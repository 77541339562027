import React from 'react';
import { NewsEvent } from '../customTypes';

interface NewsTableProps {
	newsEvents: NewsEvent[];
}

const NewsTable: React.FC<NewsTableProps> = ({ newsEvents }) => {
	return (
		<div className='overflow-x-auto'>
			<table className='min-w-full divide-y divide-gray-200'>
				<thead className='bg-gray-50'>
					<tr>
						<th
							scope='col'
							className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
						>
							Account
						</th>
						<th
							scope='col'
							className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
						>
							Event
						</th>
						<th
							scope='col'
							className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
						>
							Date
						</th>
					</tr>
				</thead>
				<tbody className='bg-white divide-y divide-gray-200'>
					{newsEvents.map((event) => (
						<tr key={event.article_id} className='hover:bg-gray-50'>
							<td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
								{event.company.name}
							</td>
							<td className='px-6 py-4'>
								<div className='text-sm font-medium text-gray-900'>
									<a
										href={event.url}
										target='_blank'
										rel='noopener noreferrer'
										className='text-indigo-600 hover:text-indigo-900'
									>
										{event.title}
									</a>
								</div>
							</td>
							<td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
								{new Date(event.published_date).toLocaleString()}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default NewsTable;
