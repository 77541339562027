// useTokenRefresh.ts
import { useEffect } from 'react';
import axios from 'axios';

interface DecodedToken {
  exp: number; // Assuming the token contains an 'exp' field with a Unix timestamp
  // Add other fields from your token if necessary
}

const parseJwt = (token: string): DecodedToken | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
};

const useTokenRefresh = (onTokenRefreshFail: () => void) => {
  useEffect(() => {
    const refreshTokenEvery = (expiryTimeInSeconds: number) => {
      const refreshBeforeExpiry = (expiryTimeInSeconds - 60) * 1000;
      setTimeout(refreshAccessToken, refreshBeforeExpiry);
    };

    const refreshAccessToken = async () => {
      const apiBaseUrl =
          process.env.REACT_APP_NODE_ENV === 'production'
            ? '/api' // Use /api for production (handled by Nginx)
            : 'http://localhost:8000/api'; // Use localhost during development
      const url = `${apiBaseUrl}/authentication/token/refresh/`;
      const refreshToken = localStorage.getItem('refresh_token');
      const payload = { "refresh": refreshToken };
      const headers = {
        'Content-Type': 'application/json',
        // Include other headers if necessary
      };
      try {
        const response = await axios.post(url, payload, { headers });
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
      } catch (error) {
        console.error(error);

        console.log('Attempted request:', {
          url,
          method: 'POST',
          headers,
          payload: { refreshToken }
        });

        // Call the failure handler
        onTokenRefreshFail();
      }
    };

    const calculateTokenExpiryTime = () => {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) return 0;

      const decoded = parseJwt(accessToken);
      if (!decoded || !decoded.exp) return 0;

      const currentTime = Date.now() / 1000; // Current time in seconds
      return Math.max(0, decoded.exp - currentTime - 300); // Refresh 5 minutes before expiry, in seconds
    };

    const expiryTime = calculateTokenExpiryTime();
    const refreshTimeout = setTimeout(refreshAccessToken, expiryTime * 1000); // Convert to milliseconds

    // Cleanup
    return () => clearTimeout(refreshTimeout);
  }, [onTokenRefreshFail]);
};

export default useTokenRefresh;
