// frontend/src/components/HubSpotCard.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface NewsEvent {
	id: number;
	title: string;
	url: string;
	published_date: string;
}

const HubSpotCard: React.FC = () => {
	const [news, setNews] = useState<NewsEvent[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const fetchCardData = async () => {
			const params = new URLSearchParams(window.location.search);
			const companyName = params.get('companyName');

			if (!companyName) {
				setError('No company name provided');
				setLoading(false);
				return;
			}

			try {
				const response = await axios.get(
					`/newstracker/hubspot/card/?companyName=${companyName}`
				);
				setNews(response.data.body);
			} catch (err) {
				setError('Failed to load news');
			} finally {
				setLoading(false);
			}
		};

		fetchCardData();
	}, []);

	if (loading) return <div>Loading...</div>;
	if (error) return <div>{error}</div>;

	return (
		<div style={{ padding: '10px', fontFamily: 'Arial, sans-serif' }}>
			<h3 style={{ fontSize: '16px', marginBottom: '10px' }}>Latest News</h3>
			<ul style={{ listStyleType: 'none', padding: 0 }}>
				{news.map((event) => (
					<li key={event.id} style={{ marginBottom: '8px' }}>
						<a
							href={event.url}
							target='_blank'
							rel='noopener noreferrer'
							style={{ color: '#0073e6', textDecoration: 'none' }}
						>
							{event.title}
						</a>
					</li>
				))}
			</ul>
			<a
				href={news.length > 0 ? news[0].url : '#'}
				target='_blank'
				rel='noopener noreferrer'
				style={{
					display: 'block',
					marginTop: '10px',
					color: '#ffffff',
					backgroundColor: '#0073e6',
					padding: '8px',
					textAlign: 'center',
					borderRadius: '4px',
					textDecoration: 'none',
				}}
			>
				View More
			</a>
		</div>
	);
};

export default HubSpotCard;
