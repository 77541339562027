import { SparklesIcon } from '@heroicons/react/20/solid';
import { useState, useEffect } from 'react';
import axiosApiInstance from '../axiosApi';
import { ResearchTemplate, ResearchBlock } from '../customTypes';
import ResearchTemplateBlock from './ResearchTemplateBlock';

const ResearchProfileTemplate: React.FC<ResearchTemplate> = ({
	template_id,
}) => {
	const [researchTemplateBlocks, setResearchTemplateBlocks] = useState<
		ResearchBlock[]
	>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	const [newResearchTemplateBlock, setNewResearchTemplateBlock] =
		useState<ResearchBlock>({
			block_id: 0,
			name: '',
			prompt: '',
		});
	const [isAddingResearchTemplateBlock, setIsAddingResearchTemplateBlock] =
		useState(false);

	const handleAddResearchTemplateBlock = () => {
		setIsAddingResearchTemplateBlock(true);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setNewResearchTemplateBlock((prevResearchTemplateBlock) => ({
			...prevResearchTemplateBlock,
			[name]: value,
		}));
	};

	useEffect(() => {
		fetchResearchTemplate();
	}, [template_id]);

	// Get the research template blocks
	const fetchResearchTemplate = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const response = await axiosApiInstance.get<ResearchBlock[]>(
				`/newstracker/researchtemplates/${template_id}/blocks/`
			);
			setResearchTemplateBlocks(response.data);
		} catch (err) {
			console.error('Error fetching research template blocks:', err);
			setError(
				'Failed to fetch research template blocks. Please try again later.'
			);
		} finally {
			setIsLoading(false);
		}
	};

	// Create a new block
	const handleSaveResearchTemplateBlock = async () => {
		if (newResearchTemplateBlock.name.trim() === '') return;

		try {
			await axiosApiInstance.post(
				`/newstracker/researchtemplates/${template_id}/blocks/`,
				newResearchTemplateBlock
			);
			fetchResearchTemplate();
			setNewResearchTemplateBlock({
				block_id: 0,
				name: '',
				prompt: '',
			});
			setIsAddingResearchTemplateBlock(false);
		} catch (error) {
			console.error('Error saving research template block:', error);
			setError('Failed to save new research template block. Please try again.');
		}
	};

	// Update existing block
	const handleUpdateResearchTemplateBlock = async (
		updatedResearchTemplateBlock: ResearchBlock
	) => {
		try {
			await axiosApiInstance.patch(
				`/newstracker/researchtemplates/${template_id}/blocks/${updatedResearchTemplateBlock.block_id}/`,
				updatedResearchTemplateBlock
			);
			fetchResearchTemplate();
		} catch (error) {
			console.error('Error updating research template block:', error);
			setError('Failed to update research template block. Please try again.');
		}
	};

	// Delete a block
	const handleDeleteResearchTemplateBlock = async (
		researchTemplateBlockId: number
	) => {
		try {
			await axiosApiInstance.delete(
				`/newstracker/researchtemplates/${template_id}/blocks/${researchTemplateBlockId}/`
			);
			fetchResearchTemplate();
		} catch (error) {
			console.error('Error deleting research template block:', error);
			setError('Failed to delete research template block. Please try again.');
		}
	};

	return (
		// <div className='overflow-hidden bg-white shadow sm:rounded-lg lg:px-8 pt-4'>
		<div className='mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8'>
			<h1 className='sr-only'>Research Profile Template</h1>
			<main className='px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20'>
				<div className='px-4 sm:px-0'>
					<h3 className='text-base font-semibold leading-7 text-gray-900'>
						Research Profile Template
					</h3>
				</div>
				{isLoading ? (
					<div>Loading research template blocks...</div>
				) : error ? (
					<div>Error: {error}</div>
				) : (
					<div className='mt-6 border-t border-gray-100'>
						<div className='divide-y divide-gray-100'>
							<div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
								<div className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0'>
									<ul className='divide-y divide-gray-100 rounded-md border border-gray-200'>
										{researchTemplateBlocks.map((block, index) => (
											<ResearchTemplateBlock
												key={index}
												researchTemplateBlock={block}
												onUpdate={handleUpdateResearchTemplateBlock}
												onDelete={handleDeleteResearchTemplateBlock}
											/>
										))}
										{isAddingResearchTemplateBlock && (
											<li className='flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6'>
												<div className='flex w-0 flex-1 items-center'>
													<SparklesIcon
														className='h-5 w-5 flex-shrink-0 text-gray-400'
														aria-hidden='true'
													/>
													<div className='ml-4 flex min-w-0 flex-1 gap-2'>
														<input
															type='text'
															name='name'
															placeholder='Title'
															value={newResearchTemplateBlock.name}
															onChange={handleInputChange}
															className='border rounded p-1 w-full'
														/>
														<input
															type='text'
															name='prompt'
															placeholder='What would you like to discover?'
															value={newResearchTemplateBlock.prompt}
															onChange={handleInputChange}
															className='border rounded p-1 w-full'
														/>
													</div>
												</div>
												<div className='ml-4 flex flex-shrink-0 space-x-4'>
													<button
														type='button'
														onClick={handleSaveResearchTemplateBlock}
														className='rounded-md bg-white font-medium text-blue-600 hover:text-blue-500'
													>
														Save
													</button>
												</div>
											</li>
										)}
										{/* Button to Add Trigger */}
										{!isAddingResearchTemplateBlock && (
											<button
												className='w-full text-left rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 p-4'
												onClick={handleAddResearchTemplateBlock}
											>
												Add New Research Section
											</button>
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				)}
			</main>
		</div>
	);
};

export default ResearchProfileTemplate;
