// TokenRefreshWrapper.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTokenRefresh from './useTokenRefresh';

interface TokenRefreshWrapperProps {
	children: React.ReactNode;
}

const TokenRefreshWrapper: React.FC<TokenRefreshWrapperProps> = ({
	children,
}) => {
	const navigate = useNavigate();

	const handleTokenRefreshFail = () => {
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
		navigate('/signin');
	};

	useTokenRefresh(handleTokenRefreshFail);

	return <>{children}</>;
};

export default TokenRefreshWrapper;
