// src/axiosApi.ts
import { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import axios from 'axios';

// Define interfaces for your token objects
interface AccessToken {
  access: string;
}

interface RefreshToken {
  refresh: string;
}

const apiBaseUrl =
			process.env.REACT_APP_NODE_ENV === 'production'
				? '/api' // Use /api for production (handled by Nginx)
				: 'http://localhost:8000/api'; // Use localhost during development
		

// Create an instance of Axios
const axiosApiInstance = axios.create({
  baseURL: `${apiBaseUrl}/`, // Your API base URL
});

// Request interceptor to add JWT token to headers
axiosApiInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
    config.headers = config.headers as AxiosRequestHeaders || {};
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // or 'Bearer ' if your backend expects Bearer tokens
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to refresh token on receiving token expired error
axiosApiInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig & { _retry?: boolean } = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken: string | null = localStorage.getItem('refresh_token');

      if (refreshToken) {
        try {
          const refreshResponse = await axios.post(`${apiBaseUrl}/authentication/token/refresh/`, { "refresh": refreshToken });
          if (refreshResponse.status === 200) {
            localStorage.setItem('access_token', refreshResponse.data.access);
            axios.defaults.headers.common['Authorization'] = `Bearer ${refreshResponse.data.access}`;
            return axiosApiInstance(originalRequest);
          }
        } catch (refreshError) {
          console.log(refreshToken)
          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axiosApiInstance;
