import React, { useState } from 'react';
import { ResearchBlock } from '../customTypes';
import { SparklesIcon } from '@heroicons/react/20/solid';

interface ResearchTemplateBlockProps {
	researchTemplateBlock: ResearchBlock;
	onUpdate: (updatedResearchTemplateBlock: ResearchBlock) => void;
	onDelete: (researchTemplateBlockId: number) => void;
}

const ResearchTemplateBlock: React.FC<ResearchTemplateBlockProps> = ({
	researchTemplateBlock,
	onUpdate,
	onDelete,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [name, setName] = useState(researchTemplateBlock.name);
	const [prompt, setPrompt] = useState(researchTemplateBlock.prompt);

	const handleUpdate = () => {
		onUpdate({ ...researchTemplateBlock, name, prompt });
		setIsEditing(false);
	};

	return (
		<li className='flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6'>
			{isEditing ? (
				<>
					<div className='flex w-0 flex-1 items-center'>
						<SparklesIcon
							className='h-5 w-5 flex-shrink-0 text-gray-400'
							aria-hidden='true'
						/>
						<input
							type='text'
							value={name}
							onChange={(e) => setName(e.target.value)}
							className='border rounded p-1 w-full mx-4'
						/>
						<input
							type='text'
							value={prompt}
							onChange={(e) => setPrompt(e.target.value)}
							className='border rounded p-1 w-full'
						/>
					</div>
					<div className='ml-4 flex flex-shrink-0 space-x-4'>
						<button
							type='button'
							onClick={handleUpdate}
							className='rounded-md bg-white font-medium text-blue-600 hover:text-blue-500'
						>
							Save
						</button>
						<button
							type='button'
							onClick={() => setIsEditing(false)}
							className='rounded-md bg-white font-medium text-gray-600 hover:text-gray-500'
						>
							Cancel
						</button>
					</div>
				</>
			) : (
				<>
					<div className='flex w-0 flex-1 items-center'>
						<SparklesIcon
							className='h-5 w-5 flex-shrink-0 text-gray-400'
							aria-hidden='true'
						/>
						<span className='ml-4 truncate font-medium'>
							{researchTemplateBlock.name}
						</span>
						<span className='flex-shrink-0 text-gray-400 mx-4'>
							{researchTemplateBlock.prompt}
						</span>
					</div>
					<div className='ml-4 flex flex-shrink-0 space-x-4'>
						<button
							type='button'
							className='rounded-md bg-white font-medium text-blue-600 hover:text-blue-500'
							onClick={() => setIsEditing(true)}
						>
							Edit
						</button>
						<button
							type='button'
							className='rounded-md bg-white font-medium text-red-600 hover:text-red-500'
							onClick={() => onDelete(researchTemplateBlock.block_id)}
						>
							Delete
						</button>
					</div>
				</>
			)}
		</li>
	);
};

export default ResearchTemplateBlock;
