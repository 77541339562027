import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosApiInstance from '../axiosApi';

const HubSpotCallback: React.FC = () => {
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();
	const location = useLocation();
	const isCallbackExecuted = useRef(false);

	const handleCallback = useCallback(async () => {
		if (isCallbackExecuted.current) return;
		isCallbackExecuted.current = true;

		const urlParams = new URLSearchParams(location.search);
		const code = urlParams.get('code');

		if (!code) {
			setError('No code provided in the URL');
			return;
		}

		try {
			const response = await axiosApiInstance.post(
				'/authentication/hubspot/callback/',
				{ code }
			);
			console.log('HubSpot connection successful:', response.data);

			// Redirect to Dashboard with a success message
			navigate('/dashboard', {
				state: { message: 'HubSpot connected successfully!' },
			});
		} catch (err) {
			console.error('Error connecting to HubSpot:', err);
			setError('Failed to connect to HubSpot. Please try again.');
		}
	}, [navigate, location]);

	useEffect(() => {
		handleCallback();
	}, [handleCallback]);

	if (error) {
		return <div>Error: {error}</div>;
	}

	return <div>Connecting to HubSpot...</div>;
};

export default HubSpotCallback;
