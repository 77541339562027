// PrivateRoute.tsx
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import TokenRefreshWrapper from './TokenRefreshWrapper';

interface PrivateRouteProps {
	isAuthenticated: boolean;
	redirectPath?: string;
	children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
	isAuthenticated,
	redirectPath = '/signin',
	children,
}) => {
	if (!isAuthenticated) {
		return <Navigate to={redirectPath} />;
	}

	return <TokenRefreshWrapper>{children}</TokenRefreshWrapper>;
};

export default PrivateRoute;
