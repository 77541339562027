import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, Transition, Tab } from '@headlessui/react';
import {
	BellIcon,
	MagnifyingGlassIcon,
	UserCircleIcon,
	PlusIcon,
	BoltIcon,
	XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import axiosApiInstance from './axiosApi';
import NewsTable from './components/NewsTable';
import { NewsEvent, UserInfo } from './customTypes';
import prospectMonitorLogo from './assets/prospectmonitor.png';
import Watchlist from './components/Watchlist'; // Add this import

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

const Dashboard: React.FC = () => {
	const [newsEvents, setNewsEvents] = useState<NewsEvent[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	const [user, setUser] = useState<UserInfo>({ first_name: '', last_name: '' });
	const [notification, setNotification] = useState<string | null>(null);
	const navigate = useNavigate();
	const location = useLocation();
	const [activeTab, setActiveTab] = useState('all');
	const [showNotification, setShowNotification] = useState(false);

	useEffect(() => {
		fetchUserInfo();
		fetchNewsEvents();

		if (location.state && location.state.message) {
			setNotification(location.state.message);
			navigate(location.pathname, { replace: true, state: {} });
		}
	}, [location, navigate]);

	const fetchUserInfo = async () => {
		try {
			const response = await axiosApiInstance.get<UserInfo>(
				'/authentication/user/'
			);
			setUser(response.data);
		} catch (err) {
			console.error(err);
		}
	};

	const fetchNewsEvents = async () => {
		try {
			const response = await axiosApiInstance.get<NewsEvent[]>(
				'/newstracker/all-news-events/'
			);
			setNewsEvents(response.data);
		} catch (error) {
			console.error('Error fetching news events:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const filteredNewsEvents = newsEvents.filter(
		(event) =>
			event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
			event.company.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const tabs = [
		{ name: 'All News', value: 'all' },
		{ name: 'Watchlist', value: 'watchlist' },
		{ name: 'Triggers', value: 'triggers' },
	];

	const userNavigation = [
		{ name: 'Profile', href: '#' },
		{ name: 'Settings', href: '#' },
		{ name: 'Sign out', href: '#' },
	];

	return (
		<div className='min-h-screen bg-gray-100'>
			<div className='bg-white shadow-sm'>
				<header className='px-4 sm:px-6 lg:px-8'>
					<div className='flex items-center justify-between h-16'>
						<img
							src={prospectMonitorLogo}
							alt='Prospect Monitor'
							className='h-8'
						/>
						<div className='flex items-center'>
							<div className='relative'>
								<button
									type='button'
									className='p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
								>
									<span className='sr-only'>View notifications</span>
									<BellIcon className='h-6 w-6' aria-hidden='true' />
									{notification && (
										<span className='absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white' />
									)}
								</button>
								{notification && (
									<div className='absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg z-10 p-4'>
										<div className='flex justify-between items-start'>
											<p className='text-sm text-gray-600'>{notification}</p>
											<button
												onClick={() => setNotification(null)}
												className='text-gray-400 hover:text-gray-500'
											>
												<XMarkIcon className='h-5 w-5' />
											</button>
										</div>
									</div>
								)}
							</div>
							<Menu as='div' className='relative ml-3'>
								<Menu.Button className='flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
									<UserCircleIcon
										className='h-8 w-8 rounded-full'
										aria-hidden='true'
									/>
									<span className='ml-2 text-gray-700'>
										{user.first_name} {user.last_name}
									</span>
									<ChevronDownIcon
										className='ml-1 h-5 w-5 text-gray-400'
										aria-hidden='true'
									/>
								</Menu.Button>
								<Transition
									as={Fragment}
									enter='transition ease-out duration-100'
									enterFrom='transform opacity-0 scale-95'
									enterTo='transform opacity-100 scale-100'
									leave='transition ease-in duration-75'
									leaveFrom='transform opacity-100 scale-100'
									leaveTo='transform opacity-0 scale-95'
								>
									<Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
										{userNavigation.map((item) => (
											<Menu.Item key={item.name}>
												{({ active }) => (
													<a
														href={item.href}
														className={classNames(
															active ? 'bg-gray-100' : '',
															'block px-4 py-2 text-sm text-gray-700'
														)}
													>
														{item.name}
													</a>
												)}
											</Menu.Item>
										))}
									</Menu.Items>
								</Transition>
							</Menu>
						</div>
					</div>
				</header>
			</div>

			<main className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
				<div className='mb-6 flex justify-between items-center'>
					<div className='relative rounded-md shadow-sm w-full max-w-md'>
						<input
							type='text'
							name='search'
							id='search'
							className='block w-full rounded-md border-gray-300 pl-10 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
							placeholder='Search'
							value={searchTerm}
							onChange={handleSearchChange}
						/>
						<div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
							<MagnifyingGlassIcon
								className='h-5 w-5 text-gray-400'
								aria-hidden='true'
							/>
						</div>
					</div>
					<div className='flex space-x-4 ml-4'>
						<button
							type='button'
							className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						>
							<PlusIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
							Watchlist
						</button>
						<button
							type='button'
							className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						>
							<BoltIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
							Triggers
						</button>
					</div>
				</div>

				<div className='bg-white rounded-lg shadow'>
					<div className='p-4'>
						{isLoading ? (
							<p className='text-center text-gray-500'>Loading...</p>
						) : (
							<NewsTable newsEvents={filteredNewsEvents} />
						)}
					</div>
				</div>
			</main>
		</div>
	);
};

export default Dashboard;
