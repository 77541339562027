import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SecondaryNavigationItem, ResearchTemplate } from '../customTypes';
import { XMarkIcon } from '@heroicons/react/20/solid';
import axiosApiInstance from '../axiosApi';
import UserSettings from './UserSettings';
import ResearchProfileTemplate from './ResearchProfileTemplate';

interface UserProfileProps {
	onLogOut: () => void;
	initialTab?: string;
}

const UserProfile: React.FC<UserProfileProps> = ({
	onLogOut,
	initialTab = 'Settings',
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [templates, setTemplates] = useState<ResearchTemplate[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [activeTab, setActiveTab] = useState(initialTab);

	useEffect(() => {
		fetchResearchTemplates();
	}, []);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const tab = searchParams.get('tab');
		if (tab) {
			setActiveTab(tab);
		}
	}, [location]);

	const fetchResearchTemplates = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const response = await axiosApiInstance.get<ResearchTemplate[]>(
				'/newstracker/researchtemplates/'
			);
			setTemplates(response.data);
			if (response.data.length === 0) {
				// If no templates exist, create a default one
				await createDefaultTemplate();
			}
		} catch (err) {
			console.error('Error fetching templates:', err);
			setError('Failed to fetch research templates. Please try again later.');
		} finally {
			setIsLoading(false);
		}
	};

	const createDefaultTemplate = async () => {
		try {
			const response = await axiosApiInstance.post<ResearchTemplate>(
				'/newstracker/researchtemplates/',
				{
					name: 'Default Research Template',
				}
			);
			setTemplates([response.data]);
		} catch (err) {
			console.error('Error creating default template:', err);
			setError('Failed to create a default template. Please try again later.');
		}
	};

	const secondaryNavigation: SecondaryNavigationItem[] = [
		// Create a share a user profile indicating some of the goals and information on the client's company
		// {
		// 	name: 'My Profile',
		// 	current: activeTab === 'My Profile',
		// },
		{
			name: 'Settings',
			current: activeTab === 'Settings',
		},
		// {
		// 	name: 'My Sales Profile',
		// 	current: activeTab === 'My Sales Profile',
		// },
		{
			name: 'Research Profile Template',
			current: activeTab === 'Research Profile Template',
		},
	];

	return (
		<main className='bg-white border-t border-black'>
			<header>
				{/* Secondary navigation */}
				<nav className='flex overflow-x-auto border-b border-black/10 py-4'>
					<ul className='flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-700 sm:px-6 lg:px-8'>
						{secondaryNavigation.map((item) => (
							<li key={item.name}>
								<button
									onClick={() => {
										setActiveTab(item.name);
									}}
									className={item.current ? 'text-red-400' : ''}
								>
									{item.name}
								</button>
							</li>
						))}
						<li className='ml-auto flex flex-shrink-0 self-end'>
							<button
								type='button'
								className='inline-flex rounded-md bg-white text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2'
								onClick={() => {
									navigate('/dashboard');
								}}
							>
								<span className='sr-only'>Close</span>
								<XMarkIcon className='h-5 w-5' aria-hidden='true' />
							</button>
						</li>
					</ul>
				</nav>
			</header>

			{activeTab === 'Settings' && <UserSettings />}

			{activeTab === 'Research Profile Template' && (
				<>
					{isLoading ? (
						<div>Loading research templates...</div>
					) : error ? (
						<div>Error: {error}</div>
					) : templates.length > 0 ? (
						<ResearchProfileTemplate template_id={templates[0].template_id} />
					) : (
						<div>No research templates available. Please create one.</div>
					)}
				</>
			)}

			{/* {activeTab === 'My Sales Profile' && (
				<>
					<h1>First Name</h1>
				</>
			)} */}
		</main>
	);
};

export default UserProfile;
