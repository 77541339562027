import React, { useState, FormEvent, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';

const SignUp: FunctionComponent<{}> = () => {
	const [email, setEmail] = useState<string>('');
	const [password1, setPassword1] = useState<string>('');
	const [password2, setPassword2] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [inviteKey, setInviteKey] = useState<string>('');
	const [isRegistered, setIsRegistered] = useState<boolean>(false);
	const navigate = useNavigate();

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (password1 !== password2) {
			alert('Passwords do not match. Please try again.');
			return; // Prevents the form from being submitted
		}

		const csrfToken = Cookies.get('csrftoken');

		const data = qs.stringify({
			username: email,
			email,
			password1,
			password2,
			first_name: firstName,
			last_name: lastName,
			invite_key: inviteKey,
		});

		const apiBaseUrl =
			process.env.REACT_APP_NODE_ENV === 'production'
				? '/api' // Use /api for production (handled by Nginx)
				: 'http://localhost:8000/api'; // Use localhost during development

		axios
			.post(`${apiBaseUrl}/authentication/registration/`, data, {
				headers: {
					'X-CSRFToken': csrfToken,
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then((response) => {
				console.log('Registration successful', response.data);
				setIsRegistered(true);
			})
			.catch((err) => {
				console.error('Registration error', err.response.data);
				alert('Registration failed. Please check your details and try again.');
			});
	};

	const handleSignInRedirect = () => {
		navigate('/signin');
	};

	if (isRegistered) {
		return (
			<div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
				<div className='sm:mx-auto sm:w-full sm:max-w-sm'>
					<h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
						Registration Successful!
					</h2>
					<p className='mt-2 text-center text-sm text-gray-600'>
						Please check your email to verify your account. Once verified, you
						can log in.
					</p>
					<div className='mt-5 text-center'>
						<button
							onClick={handleSignInRedirect}
							className='font-semibold text-indigo-600 hover:text-indigo-500'
						>
							Go to Sign In
						</button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
				<div className='sm:mx-auto sm:w-full sm:max-w-sm'>
					{/* <img
						className='mx-auto h-10 w-auto'
						src='https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600'
						alt='Your Company'
					/> */}
					<h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
						Sign up for an account
					</h2>
				</div>

				<div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
					<form className='space-y-6' onSubmit={handleSubmit}>
						<div>
							<label
								htmlFor='firstName'
								className='block text-sm font-medium leading-6 text-gray-900'
							>
								First Name
							</label>
							<div className='mt-2'>
								<input
									id='firstName'
									name='firstName'
									type='text'
									required
									className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<label
								htmlFor='lastName'
								className='block text-sm font-medium leading-6 text-gray-900'
							>
								Last Name
							</label>
							<div className='mt-2'>
								<input
									id='lastName'
									name='lastName'
									type='text'
									required
									className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<label
								htmlFor='email'
								className='block text-sm font-medium leading-6 text-gray-900'
							>
								Email address
							</label>
							<div className='mt-2'>
								<input
									id='email'
									name='email'
									type='email'
									autoComplete='email'
									required
									className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<div className='flex items-center justify-between'>
								<label
									htmlFor='password'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Password
								</label>
							</div>
							<div className='mt-2'>
								<input
									id='password1'
									name='password1'
									type='password'
									required
									className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									value={password1}
									onChange={(e) => setPassword1(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<div className='flex items-center justify-between'>
								<label
									htmlFor='password'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Confirm Password
								</label>
							</div>
							<div className='mt-2'>
								<input
									id='password2'
									name='password2'
									type='password'
									required
									className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									value={password2}
									onChange={(e) => setPassword2(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<label
								htmlFor='inviteKey'
								className='block text-sm font-medium leading-6 text-gray-900'
							>
								Invite Key
							</label>
							<div className='mt-2'>
								<input
									id='inviteKey'
									name='inviteKey'
									type='text'
									required
									className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									value={inviteKey}
									onChange={(e) => setInviteKey(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<button
								type='submit'
								className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
							>
								Sign up
							</button>
						</div>
					</form>

					{/* ... TODO Social Sign Up ... */}
					<p className='mt-10 text-center text-sm text-gray-500'>
						Already have an account?{' '}
						<button
							onClick={handleSignInRedirect}
							className='font-semibold text-indigo-600 hover:text-indigo-500'
						>
							Log in.
						</button>
					</p>
				</div>
			</div>
		</>
	);
};

export default SignUp;
