import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosApiInstance from '../axiosApi';

const PasswordResetConfirm: React.FC = () => {
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState('');
	const { uidb64, token } = useParams<{ uidb64: string; token: string }>();
	const navigate = useNavigate();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (newPassword !== confirmPassword) {
			setMessage("Passwords don't match");
			return;
		}
		try {
			const response = await axiosApiInstance.post(
				`/authentication/password-reset-confirm/${uidb64}/${token}/`,
				{ new_password: newPassword }
			);
			setMessage(response.data.detail);
			setTimeout(() => navigate('/signin'), 3000);
		} catch (error) {
			setMessage('An error occurred. Please try again.');
		}
	};

	return (
		<div className='min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'>
			<div className='max-w-md w-full space-y-8'>
				<div>
					<h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
						Set New Password
					</h2>
				</div>
				<form className='mt-8 space-y-6' onSubmit={handleSubmit}>
					<input type='hidden' name='remember' defaultValue='true' />
					<div className='rounded-md shadow-sm -space-y-px'>
						<div>
							<label htmlFor='new-password' className='sr-only'>
								New Password
							</label>
							<input
								id='new-password'
								name='new-password'
								type='password'
								required
								className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
								placeholder='New Password'
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
						</div>
						<div>
							<label htmlFor='confirm-password' className='sr-only'>
								Confirm Password
							</label>
							<input
								id='confirm-password'
								name='confirm-password'
								type='password'
								required
								className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
								placeholder='Confirm Password'
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
						</div>
					</div>

					<div>
						<button
							type='submit'
							className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						>
							Reset Password
						</button>
					</div>
				</form>
				{message && (
					<p className='mt-2 text-center text-sm text-gray-600'>{message}</p>
				)}
			</div>
		</div>
	);
};

export default PasswordResetConfirm;
