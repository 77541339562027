import React, { useState, useEffect } from 'react';
import { NewsEvent, Contact } from '../customTypes';
import {
	ChevronDownIcon,
	ChevronUpIcon,
	EnvelopeIcon,
	DocumentCheckIcon,
} from '@heroicons/react/24/outline';
import MarkdownDisplay from './MarkdownDisplay';
import ContactSelectionModal from './ContactSelectionModal';
import axiosApiInstance from '../axiosApi';
import GeneratedEmailModal from './GeneratedEmailModal'; // Add this import

interface NewsTableProps {
	newsEvents: NewsEvent[];
}

const NewsTable: React.FC<NewsTableProps> = ({ newsEvents }) => {
	const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
	const [isContactModalOpen, setIsContactModalOpen] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState<NewsEvent | null>(null);
	const [generatedEmail, setGeneratedEmail] = useState<string | null>(null);
	const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
	const [events, setEvents] = useState<NewsEvent[]>(newsEvents);

	useEffect(() => {
		const handleSummaryResult = async (event: MessageEvent) => {
			if (event.data.type === 'SUMMARY_RESULT') {
				const { articleId, summary, finalUrl } = event.data;

				// Update the state with the new summary and URL
				setEvents((prevEvents) =>
					prevEvents.map((event) =>
						event.article_id === articleId
							? { ...event, impact_analysis: summary, url: finalUrl }
							: event
					)
				);

				// Update the backend with the new URL if it's different
				const currentEvent = events.find((e) => e.article_id === articleId);
				if (currentEvent && currentEvent.url !== finalUrl) {
					try {
						await axiosApiInstance.patch(
							`/newstracker/news-events/${articleId}/`,
							{
								url: finalUrl,
							}
						);
					} catch (error) {
						console.error('Failed to update article URL:', error);
					}
				}
			}
		};

		window.addEventListener('message', handleSummaryResult);

		return () => {
			window.removeEventListener('message', handleSummaryResult);
		};
	}, [events]);

	const toggleRow = (articleId: number) => {
		setExpandedRows((prevState) => {
			const newState = new Set(prevState);
			if (newState.has(articleId)) {
				newState.delete(articleId);
			} else {
				newState.add(articleId);
			}
			return newState;
		});
	};

	const handleGenerateEmail = (event: NewsEvent) => {
		setSelectedEvent(event);
		setIsContactModalOpen(true);
	};

	const handleContactSelected = async (contact: Contact) => {
		setIsContactModalOpen(false);
		if (selectedEvent) {
			try {
				const response = await axiosApiInstance.post(
					'/newstracker/generate-email/',
					{
						summary: selectedEvent.impact_analysis,
						company: selectedEvent.company.name,
						contact: contact,
					}
				);
				setGeneratedEmail(response.data.email);
				setIsEmailModalOpen(true); // Open the email modal
			} catch (error) {
				console.error('Error generating email:', error);
				// Handle error (e.g., show an error message to the user)
			}
		}
	};

	const handleSummarizeWithPlugin = (event: NewsEvent) => {
		// Send a message to the Chrome plugin
		window.postMessage(
			{
				type: 'SUMMARIZE_ARTICLE',
				url: event.url,
				articleId: event.article_id,
			},
			'*'
		);
	};

	return (
		<div className='overflow-x-auto'>
			<table className='min-w-full divide-y divide-gray-200'>
				<thead className='bg-gray-50'>
					<tr>
						<th
							scope='col'
							className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
						>
							Account
						</th>
						<th
							scope='col'
							className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
						>
							Event
						</th>
						<th
							scope='col'
							className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
						>
							Date
						</th>
						<th scope='col' className='relative px-6 py-3'>
							<span className='sr-only'>Actions</span>
						</th>
					</tr>
				</thead>
				<tbody className='bg-white divide-y divide-gray-200'>
					{newsEvents.map((event) => (
						<React.Fragment key={event.article_id}>
							<tr className='hover:bg-gray-50'>
								<td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
									{event.company.name}
								</td>
								<td className='px-6 py-4'>
									<div className='text-sm font-medium text-gray-900'>
										<a
											href={event.url}
											target='_blank'
											rel='noopener noreferrer'
											className='text-indigo-600 hover:text-indigo-900'
										>
											{event.title}
										</a>
									</div>
								</td>
								<td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
									{new Date(event.published_date).toLocaleString()}
								</td>
								<td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
									<div className='flex justify-end space-x-2'>
										{event.impact_analysis && (
											<button
												onClick={() => toggleRow(event.article_id)}
												className='text-gray-400 hover:text-gray-600'
											>
												{expandedRows.has(event.article_id) ? (
													<ChevronUpIcon className='h-5 w-5' />
												) : (
													<ChevronDownIcon className='h-5 w-5' />
												)}
											</button>
										)}
										<button
											onClick={() => handleGenerateEmail(event)}
											className='text-indigo-600 hover:text-indigo-900'
											title='Generate Cold Email'
										>
											<EnvelopeIcon className='h-5 w-5' />
										</button>
										<button
											onClick={() => handleSummarizeWithPlugin(event)}
											className='text-indigo-600 hover:text-indigo-900'
											title='Summarize with Plugin'
										>
											<DocumentCheckIcon className='h-5 w-5' />
										</button>
									</div>
								</td>
							</tr>
							{event.impact_analysis && expandedRows.has(event.article_id) && (
								<tr>
									<td
										colSpan={4}
										className='px-6 py-4 text-sm text-black bg-gray-50'
									>
										<div className='prose max-w-none'>
											<MarkdownDisplay content={event.impact_analysis} />
										</div>
									</td>
								</tr>
							)}
						</React.Fragment>
					))}
				</tbody>
			</table>
			<ContactSelectionModal
				isOpen={isContactModalOpen}
				onClose={() => setIsContactModalOpen(false)}
				onContactSelected={handleContactSelected}
				companyId={selectedEvent?.company.company_id}
			/>
			<GeneratedEmailModal
				isOpen={isEmailModalOpen}
				onClose={() => setIsEmailModalOpen(false)}
				email={generatedEmail}
			/>
		</div>
	);
};

export default NewsTable;
