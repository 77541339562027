import React, { useState, useEffect, FunctionComponent } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom';
import axios from 'axios';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Dashboard from './Dashboard';
import UserProfile from './components/UserProfile';
import PrivateRoute from './components/PrivateRoute';
import PasswordResetRequest from './components/PasswordResetRequest';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import HubSpotCallback from './components/HubSpotCallback';
import HubSpotCard from './components/HubSpotCard';
const App: FunctionComponent = () => {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
		!!localStorage.getItem('access_token')
	);

	const apiBaseUrl =
		process.env.REACT_APP_NODE_ENV === 'production'
			? '/api' // Use /api for production (handled by Nginx)
			: 'http://localhost:8000/api'; // Use localhost during development

	useEffect(() => {
		axios
			.get(`${apiBaseUrl}/authentication/set-csrf/`)
			.then((response) => {
				console.log('CSRF token fetched');
			})
			.catch((error) => {
				console.error('Error fetching CSRF token', error);
			});
	}, []);

	const handleLogOut = () => {
		axios
			.post(`${apiBaseUrl}/auth/logout/`)
			.then((response) => {
				localStorage.removeItem('access_token');
				localStorage.removeItem('refresh_token');
				setIsAuthenticated(false);
			})
			.catch((err) => console.error('Logout failed', err));
	};

	return (
		<Router>
			<div className='App'>
				<Routes>
					<Route
						path='/signin'
						element={<SignIn setIsAuthenticated={setIsAuthenticated} />}
					/>
					<Route path='/signup' element={<SignUp />} />
					<Route
						path='/dashboard'
						element={
							<PrivateRoute isAuthenticated={isAuthenticated}>
								<Dashboard />
							</PrivateRoute>
						}
					/>
					<Route
						path='/profile'
						element={
							<PrivateRoute isAuthenticated={isAuthenticated}>
								<UserProfile
									onLogOut={handleLogOut}
									initialTab={
										new URLSearchParams(window.location.search).get('tab') ||
										'Settings'
									}
								/>
							</PrivateRoute>
						}
					/>
					<Route path='*' element={<Navigate to='/signin' />} />
					<Route path='/password-reset' element={<PasswordResetRequest />} />
					<Route
						path='/reset-password/:uidb64/:token'
						element={<PasswordResetConfirm />}
					/>
					<Route path='/hubspot-callback' element={<HubSpotCallback />} />
					<Route path='/hubspot/card/' element={<HubSpotCard />} />
				</Routes>
			</div>
		</Router>
	);
};

export default App;
